<template>
	<div id="adoptEdit">
		<page-adopt-edit is-shop="0"></page-adopt-edit>
	</div>
</template>

<script>
import pageAdoptEdit from '@/components/layout/adopt/page-adopt-edit.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
export default{
	components:{
		pageAdoptEdit
	},
	setup(){
		onBeforeRouteLeave((to,from,next)=>{
			if( to.path == '/adopt/list'){
				store.dispatch("setKeepPage",{name:to.name,type:1})
			}else{
				store.dispatch("setKeepPage",{name:to.name,type:2})
			}
			next()
		})
	}
}
</script>

<style>
</style>
